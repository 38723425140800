import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { PageTeaser } from './pageTeaser'
import SearchBar from "material-ui-search-bar"

import Fuse from 'fuse.js'



const SearchBox = ({ fuse }) => {
    const [query, setQuery] = useState('')
    const results = fuse.search(query, { limit: 10 })

    return (
        <div style={{
            position: "relative",
            width: "max(300px, 60%)"
        }}>
            <SearchBar style={{
                width: "100%",
                backgroundColor: "var(--main-background-color)",
                boxShadow: "none",
                borderRadius: "none"
            }}
                onCancelSearch={() => setQuery('')}
                onChange={newQuery => setQuery(newQuery)}
                placeholder=''

            />
            <div className="auto-complete-box" style={{
                marginTop: "0px",
                position: "absolute",
                width: "100%",
                border: "2px solid var(--main-background-color)",
                background: "white",
                display: results.length > 0 ? "block" : "none"
            }}
            >
                {results.map(r => (
                    <Link key={r.item.path} to={"/" + r.item.path}>
                        <div style={{
                            margin: "5px",
                        }}>
                            {r.item.title}
                        </div>
                    </Link>))}
            </div>
        </div>
    )
}

export const NameSearch = () => {
    const data = useStaticQuery(graphql`
        query {
            allPagesJson {
            nodes {
                url
                title
            }
            }
            allNamesJson {
                nodes {
                id
                name
                }
            }
        }`)

    const searchdata = data.allPagesJson.nodes.map((page) => ({
        path: page.url,
        title: page.title
    }))
        .concat(
            data.allNamesJson.nodes.map((name) => ({
                path: name.id,
                title: name.name
            })))

    const fuse = new Fuse(searchdata, { keys: ['title'] })

    return (
        <SearchBox fuse={fuse} />
    )
}

const Search = ({ searchExamples = [] }) => {

    return (
        <div className="paper" style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "var(--component-background-color)",
        }}>
            <h2>Suche</h2>
            <NameSearch />
            {searchExamples.length > 0 &&
                <h3 style={{ marginTop: "40px" }}>Beispiele</h3>}
            {searchExamples.map(page => (<PageTeaser page={page} key={page.url} />))}
        </div>
    )
}

export default Search